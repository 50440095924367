@keyframes heart {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

html {
  scroll-behavior: smooth;
}
